const PalabrasGroseras = [
  "malparido",
  "hijueputa",
  "hijodeputa,",
  "Gorrero",
  "Zunga",
  "Zungo",
  "Garbimba",
  "boludo",
  "malparida",
  "malparido",
  "masturvadordeperros",
  "setentahijueputa",
  "tetranutra",
  "perro",
  "perra",
  "sarnoso",
  "sarnosa",
  "sapohijueputa",
  "sapohp",
  "sucuchamelochupa",
  "rata",
  "remalparido",
  "guevon",
  "guevona",
  "verga",
  "pene",
  "vagina",
  "ano",
  "chimbo",
  "chimba",
  "elmataabuelitas",
  "elmatanegros",
  "negros",
  "nigga",
  "negrosdemierda",
  "nepe",
  "gonorrea",
  "pendeja",
  "pendejo",
  "perrahijueputa",
  "paja",
  "pajero",
  "pajin",
  "petardo",
  "petarda",
  "cerda",
  "cerdasucia",
  "sucia",
  "cagon",
  "cagada",
  "caga",
  "selemojalacanoa",
  "comemierda",
  "elmatanegros",
  "negros",
  "nigga",
  "negrosdemierda",
  "nepe",
  "nacidoporelculo",
  "nacidopoelculo",
  "homosexual",
  "hp",
  "poronga",
  "perrahp",
  "tula",
  "prepusio",
  "peneporelculo",
  "peneporlavagina",
  "herpes",
  "Hijueputa",
  "hijodelastresmilputas",
  "coño",
  "zorra",
  "ojete",
  "orto",
  "elvergalarga",
  "elvaginon",
  "horto",
  "cancer",
  "culos",
  "culo",
  "culogrueso",
  "arrecho",
  "arrecha",
  "gey",
  "gay",
  "marico",
  "monda",
  "marica",
  "marika",
  "maricon",
  "mariko",
  "melopela",
  "cebo",
  "caremonda",
  "careverga",
  "sebo",
  "soplamonda",
  "testiculos",
  "triplehijueputa",
  "triplegonorrea",
  "sexo",
  "follar",
  "fornicar",
  "concha",
  "comeburras",
  "vergaso",
  "vergasa",
  "vergota",
  "verguita",
  "vergaporelculo",
  "vergaporlaviga",
  "violaperros",
  "violador",
  "elviolador",
  "violaabuelas",
  "elviolaabuelas",
  "violabuelas",
  "caca",
  "elsinesquinas",
];

export default PalabrasGroseras;
